import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
/* import Cart from '@/dc-it/models/Cart'
import Relation from '@/dc-it/types/Relation' */

export default class SessionClass extends Model {
    endpoint = 'sessions';

    related = ['carts']

    fields = [
      new Field('id', 'Código'),
      new Field('carts_count', 'Productos').applyMask(value => `${value} productos agregados`),
      // new Relation('carts_count', 'Productos', Cart),
    ];

    clone = () => SessionClass;
}
